html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  color: #3c4858;
  font-family: Roboto,Helvetica,Arial,sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

h2 {
  font-size: 2.4em;
  padding-bottom: 1rem;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
}

#login {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#lot {
  height: max-content;
  overflow: visible;
}

.apexcharts-tooltip {
  color: black;
}
