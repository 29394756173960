@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input,
textarea, select {
  background-color: rgb(243, 244, 246);
  padding: 0.5rem;
  border-radius: 0.2rem;
  margin-bottom: 2rem;
  color: #292929;
  border: 2px solid rgb(243, 244, 246);
  outline: none;
}

input:focus,
textarea:focus {
  border: 2px solid hsl(217, 91%, 60%);
}

@layer components {
  .btn-teal {
    @apply bg-teal-700 text-white font-bold py-2 px-8 rounded shadow border-transparent hover:bg-teal-600 active:bg-teal-900 transition-all duration-300;
  }
  .btn-blue {
    @apply bg-blue-600 text-white font-bold py-2 px-8 rounded shadow border-transparent hover:bg-blue-500 active:bg-blue-800 transition-all duration-300;
  }
  .btn-red {
    @apply bg-red-700 text-white font-bold py-2 px-8 rounded shadow border-transparent hover:bg-red-600 active:bg-red-900 transition-all duration-300;
  }
  .btn-red-transparent {
    @apply text-red-500 bg-transparent font-bold uppercase px-6 py-2 text-sm rounded outline-none focus:outline-none  ease-linear transition-all duration-300;
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid rgb(2 132 199 / var(--tw-bg-opacity)); /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}